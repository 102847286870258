import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import localVideo from './local-video.mp4';
import localPoster from './local-poster.jpg';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Title = makeShortcode("Title");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<Video>`}</inlineCode>{` component can render a Vimeo player or a html video player.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Title mdxType="Title">Vimeo</Title>
    <Video title="Carbon homepage video" vimeoId="359578263" mdxType="Video" />
    <Title mdxType="Title">Video</Title>
    <Video src="/videos/hero-video.mp4" poster="/images/poster.png" mdxType="Video">
  <track kind="captions" default src="/videos/vtt/hero-video.vtt" srcLang="en" />
    </Video>
    <Title mdxType="Title">Video in MDX</Title>
    <Video src={localVideo} poster={localPoster} mdxType="Video" />
    <h2>{`Code`}</h2>
    <Title mdxType="Title">Vimeo</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/Video/Video.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Video",
        "path": "components/Video/Video.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Video"
      }}>{`<Video title="Carbon homepage video" vimeoId="322021187" />
`}</code></pre>
    <Title mdxType="Title">Video</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/Video/Video.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Video",
        "path": "components/Video/Video.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Video"
      }}>{`<Video src="/videos/hero-video.mp4" poster="/images/poster.png">
  <track
    kind="captions"
    default
    src="/videos/vtt/hero-video.vtt"
    srcLang="en"
  />
</Video>
`}</code></pre>
    <Title mdxType="Title">Video in MDX</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/Video.mdx src=https://github.com/carbon-design-system/gatsby-theme-carbon/blob/main/packages/example/src/pages/components/Video/index.mdx",
        "path": "components/Video.mdx",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/blob/main/packages/example/src/pages/components/Video/index.mdx"
      }}>{`import localVideo from './local-video.mp4';
import localPoster from './local-poster.jpg';

<Video src={localVideo} poster={localPoster} />
`}</code></pre>
    <h3>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`vimeoId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To find your `}<inlineCode parentName="td">{`vimeoId`}</inlineCode>{`, go to the Vimeo page and find the video you want to put on your website. Once it is loaded, look at the URL and look for the numbers that come after the slash (/).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`src`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use the html `}<inlineCode parentName="td">{`<video>`}</inlineCode>{` player with a local `}<inlineCode parentName="td">{`.mp4`}</inlineCode>{` video`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Vimeo title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`poster`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provides an image to show before the video loads, only works with `}<inlineCode parentName="td">{`src`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/track"
            }}><inlineCode parentName="a">{`<track>`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`non-vimeo only`}</em>{` – Provide `}<inlineCode parentName="td">{`.vtt`}</inlineCode>{` file in your static directory to make your videos more accessible. Then add a track element with a src pointing to it Check out `}<a parentName="td" {...{
              "href": "https://developer.mozilla.org/en-US/docs/Web/API/WebVTT_API#Tutorial_on_how_to_write_a_WebVTT_file"
            }}>{`this simple tutorial`}</a>{` for getting started with writing vtt files.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`autoPlay`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether or not the video should autoplay.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      